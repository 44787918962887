<template>
  <v-toolbar
    v-bind="{...$attrs, ...$props}"
    dense
    floating
    tag="div"
  >
    <slot/>
  </v-toolbar>
</template>

<script>
export default {};
</script>

