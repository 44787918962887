<template>
  <VMapTool
    v-bind="{...$props}"
    @click="onClick"
  />
</template>

<script>
import VMapTool from '../VMapTool';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    VMapTool
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'mdi-crosshairs-gps'
    },
    initExtent: {
      type: Object,
      required: true,
      default: () => ({})
    },
    label: {
      type: String,
      required: false,
      default: 'Auf initialen Ausschnitt zoomen'
    },
    value: {}
  },
  methods: {
    onClick () {
      this.$emit('input', _cloneDeep(this.initExtent));
    }
  }
};
</script>
