<template>
  <div>
    <nobr>{{ text }}</nobr>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _floor from 'lodash/floor';

export default {
  props: {
    position: {
      type: Object,
      required: false,
      default: () => ({})
    },
  },
  computed: {
    text () {
      const x = _get(this.position, 'x');
      const y = _get(this.position, 'y');
      return `${x ? this.formatCoordinate(x) : '-'} : ${y ? this.formatCoordinate(y) : '-'} ETRS89 / UTM Zone 32N`;
    }
  },
  methods: {
    formatCoordinate (value) {
      return _floor(value).toLocaleString();
    }
  }
};
</script>
