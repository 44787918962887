<template>
  <v-card>
    <VActionRow class="pa-2">
      <VBtnResponsive
        color="grey lighten-3"
        depressed
        :disabled="loading || grundstuecke.length === 0"
        :loading="loadingExport"
        tile
        @click="onExport"
      >
        Auswahl exportieren
      </VBtnResponsive>
      <VBtnResponsive
        color="grey lighten-3"
        depressed
        :disabled="loading || grundstuecke.length === 0"
        :loading="loadingAbsage"
        tile
        @click="onCreateAbsage"
      >
        Vorkaufsrecht ablehnen
      </VBtnResponsive>
      <VBtnResponsive
        color="grey lighten-3"
        depressed
        :disabled="loading || grundstuecke.length === 0"
        :loading="loadingZusage"
        tile
        @click="onCreateZusage"
      >
        Vorkaufsrecht wahrnehmen
      </VBtnResponsive>
    </VActionRow>
    <v-data-table
      v-model="selectedGrundstuecke"
      disable-sort
      disable-pagination
      :headers="headers"
      hide-default-footer
      :items="grundstuecke"
      :items-per-page="-1"
      item-key="id"
      no-data-text="Es liegen keine Grundstücke mit Vorkaufsrechten vor."
      show-select
    >
      <template v-for="vorschrift in vorschriften" v-slot:[`item.${vorschrift.role}`]="{ item }">
        {{ hasVorkaufsrechtForVorschrift(item, vorschrift) ? 'Ja' : 'Nein' }}
      </template>
      <template #item.flurstuecke="{ item }">
        <div class="white-space-pre d-inline-block">{{ flurstueckeText(item) }}</div>
      </template>
      <template #item.zustaendig="{ item }">
        <div class="white-space-pre d-inline-block">{{ zustaendigText(item, vorschriften)}}</div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import VActionRow from '../../../../components/VActionRow';
import VBtnResponsive from '../../../../components/VBtnResponsive';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _throttle from 'lodash/throttle';

import {flurstueckeText, hasVorkaufsrechtForVorschrift} from '../../../../common/grundstueck';
import {AuskunftRepository} from '../../../../repositories/auskunft-repository';
import {JobRepository} from "../../../../repositories/job-repository";
import {ResourceRepository} from '../../../../repositories/resource-repository'
import {JOB_EXECUTING, JOB_SUBMITTED, JOB_SUCCEEDED} from "../../../../config";

export default {
  components: {
    VActionRow,
    VBtnResponsive
  },
  props: {
    auskunft: {
      type: Object,
      required: true,
      default: () => ({})
    },
    vorschriften: {
      type: Array,
      required: true,
      default: () => ([])
    },
    extent: {
      type: Object,
      required: true,
      default: () => ({})
    },
    value: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data() {
    return {
      loadingExport: false,
      loadingAbsage: false,
      loadingZusage: false,
      selectedGrundstuecke: _cloneDeep(this.value)
    };
  },
  computed: {
    grundstuecke() {
      // Nur Grundstücke mit Voraufsrechten anzeigen.
      return this.auskunft.grundstuecke
        ? this.auskunft.grundstuecke
          .filter(grundstueck => grundstueck.vorkaufsrecht)
        : [];
    },
    headers() {
      return [
        ...this.vorschriften.map(vorschrift => {
          return {
            text: vorschrift.name_short,
            value: vorschrift.role
          };
        }),
        {text: 'Grundstück / Erbbaurecht', value: 'text'},
        {text: 'Flurstück', value: 'flurstuecke'},
        {text: 'Buchungsart', value: 'buchungsart'},
        {text: 'Zuständig', value: 'zustaendig'}
      ];
    },
    loading() {
      return this.loadingAbsage || this.loadingZusage || this.loadingExport;
    }
  },
  watch: {
    auskunft() {
      this.selectedGrundstuecke = [];
    },
    grundstuecke(grundstuecke) {
      // Auskünfte mit nur einem Grundstück direkt selektieren.
      if (grundstuecke.length === 1) {
        this.selectedGrundstuecke.push(...grundstuecke);
      }
    },
    selectedGrundstuecke: {
      handler(selected) {
        if (!_isEqual(selected, this.value)) {
          this.$emit('input', _cloneDeep(selected));
        }
      },
      deep: true
    },
    value: {
      handler(value) {
        if (!_isEqual(value, this.selectedGrundstuecke)) {
          this.selectedGrundstuecke = _cloneDeep(value);
        }
      },
      deep: true
    }
  },
  methods: {
    onExport: _throttle(async function () {
      if (this.selectedGrundstuecke && this.selectedGrundstuecke.length > 0) {
        this.loadingExport = true;

        try {
          const job = await this.createExport(this.auskunft, this.selectedGrundstuecke, this.extent);
          await this.waitForJob(job.id);
          this.$store.commit('SET_SNACKBAR_TEXT',
            'Der Export wurde erfolgreich erstellt und an Ihre E-Mail-Adresse versendet.');
        } catch (error) {
          this.$store.commit('SET_ERROR', error);
        } finally {
          this.loadingExport = false;
        }
      } else {
        this.$store.commit('SET_SNACKBAR_TEXT',
          'Es wurden keine Grundstücke ausgewählt.');
      }
    }, 450, {trailing: false}),

    onCreateAbsage: _throttle(async function () {
      this.loadingAbsage = true;

      try {
        let job = await this.createAbsage(this.auskunft);
        job = await this.waitForJob(job.id);
        if (job.result && job.result.type === 'download') {
          ResourceRepository.download(job.result.resource)
        }
      } finally {
        this.loadingAbsage = false;
      }
    }, 450, {trailing: false}),

    onCreateZusage: _throttle(async function () {
      this.loadingZusage = true;

      try {
        let job = await this.createZusage(this.auskunft);
        job = await this.waitForJob(job.id);
        if (job.result && job.result.type === 'download') {
          ResourceRepository.download(job.result.resource)
        }
      } finally {
        this.loadingZusage = false;
      }
    }, 450, {trailing: false}),

    async createExport(auskunft, grundstuecke, extent) {
      const {data} = await AuskunftRepository.export(auskunft.id, {
        email: true,
        grundstuecke: grundstuecke.map(grundstueck => grundstueck.id),
        extent: [
          extent.xmin,
          extent.ymin,
          extent.xmax,
          extent.ymax
        ]
      });
      return data;
    },

    async fetchJobById(id) {
      const {data} = await JobRepository.findById(id);
      return data;
    },

    async waitForJob(id) {
      await this.timeout(1000)

      const job = await this.fetchJobById(id);
      if (job.status === JOB_SUCCEEDED) {
        return job;
      } else if (job.status === JOB_SUBMITTED || job.status === JOB_EXECUTING) {
        return this.waitForJob(id);
      } else {
        throw new Error(job.result.message);
      }
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async createAbsage(auskunft) {
      try {
        const {data} = await AuskunftRepository.createAbsage(auskunft.id);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },

    async createZusage(auskunft) {
      try {
        const {data} = await AuskunftRepository.createZusage(auskunft.id);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },

    zustaendigText(grundstueck = {}, join = '\n') {
      return grundstueck.vorkaufsrecht
        ? grundstueck.vorkaufsrechte.map(vorkaufsrecht =>
          `${vorkaufsrecht.vorschrift.name_short}: ${vorkaufsrecht.authority.name}`).join(join)
        : '';
    },
    hasVorkaufsrechtForVorschrift,
    flurstueckeText
  }
};
</script>
