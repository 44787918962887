<template>
  <div
    v-resize="onResize"
    class="fill-height position-relative"
    :style="containerStyle"
  >
    <div
      class="v-map-layout__content fill-height d-flex flex-column"
      :style="contentStyle"
    >
      <div class="flex-grow-1 position-relative">
        <slot/>

        <div class="v-map-layout__toolbar-top-right">
          <slot name="top-right"/>
        </div>
        <div class="v-map-layout__toolbar-bottom-left">
          <slot name="bottom-left"/>
        </div>
      </div>

      <div class="v-map-layout__footer">
        <slot name="footer"/>
      </div>

      <div
        v-show="drawer"
        class="v-map-layout__drawer"
        :style="drawerStyle"
      >
        <slot name="drawer"/>
      </div>
    </div>

    <div
      class="v-map-layout__sidebar d-flex flex-column fill-height"
      ref="sidebar"
      :style="sidebarStyle"
    >
      <div class="v-map-layout__sidebar-wrapper">
        <slot name="sidebar"/>
      </div>
    </div>

    <div
      class="v-map-layout__overlay"
      :style="overlayStyle"
      @click="$emit('update:sidebar', false)"
    />
  </div>
</template>

<script>
export default {
  props: {
    sidebar: {
      type: Boolean,
      required: false,
      default: false
    },
    drawer: {
      type: Boolean,
      required: false,
      default: false
    },
    sidebarWidth: {
      type: Number,
      required: false,
      default: 256
    }
  },
  data () {
    return {
      containerMaxHeight: this.calculateContainerMaxHeight(),
      overlay: true
    };
  },
  computed: {
    containerStyle () {
      return {
        'max-height': `${this.containerMaxHeight}px`
      };
    },
    sidebarStyle () {
      return {
        position: this.$vuetify.breakpoint.xs ? 'fixed' : 'absolute',
        width: this.sidebar
          ? `${this.sidebarWidth}px`
          : 0,
        'z-index': this.$vuetify.breakpoint.xs ? 6 : 3
      };
    },
    contentStyle () {
      return {
        marginRight: this.sidebar && !this.$vuetify.breakpoint.xs
          ? `${this.sidebarWidth}px`
          : 0
      };
    },
    drawerStyle () {
      return {
        'max-height': `${this.containerMaxHeight / 2}px`,
        'overflow-y': 'auto'
      };
    },
    overlayStyle () {
      return {
        display: this.sidebar && this.$vuetify.breakpoint.xs
          ? 'block'
          : 'none'
      };
    }
  },
  methods: {
    onResize () {
      this.containerMaxHeight = this.calculateContainerMaxHeight();
    },
    calculateContainerMaxHeight () {
      return window.innerHeight - this.$vuetify.application.top - this.$vuetify.application.footer;
    }
  }
};
</script>

<style>
.v-map-layout__content {
  transition: margin-right .5s;
}

.v-map-layout__sidebar {
  border-left: 1px solid #C9C9C9;
  background-color: white;
  width: 0;
  top: 0;
  right: 0;
  transition: .5s;
}

.v-map-layout__sidebar-wrapper {
  flex: 1 1 0;
  overflow-y: auto;
}

.v-map-layout__drawer {
  border-top: 1px solid #C9C9C9;
}

.v-map-layout__toolbar-top-right {
  position: absolute;
  top: 15px;
  right: 15px;
}

.v-map-layout__toolbar-bottom-left {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.v-map-layout__footer {
  border-top: 1px solid #C9C9C9;
  background-color: #F1F1F1;
}

.v-map-layout__overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}
</style>
