<template>
  <VLayoutMap
    :drawer.sync="drawer"
    :sidebar.sync="sidebar"
    :sidebar-width="!$vuetify.breakpoint.xs ? 335 : undefined"
  >
    <VMap>
      <VMapView
        :constraints="constraints"
        :extent.sync="extent"
        :scale.sync="scale"
        :ready.sync="viewReady"
        :ui="ui"
        @pointer-move="cursorPosition = $event"
        ref="view"
      >
        <VGraphic
          v-for="graphic in graphics"
          v-model="visibleGraphics"
          :key="`graphic-${graphic.attributes.id}`"
          :properties="graphic"
          :value="graphic.attributes.id"
        />
      </VMapView>
      <template v-if="visibleBasemap">
        <v-basemap
          :id="visibleBasemap.id"
        >
          <v-wmts-layer
            v-for="(service, index) in visibleBasemap.services"
            :key="`${visibleBasemap.id}-${index}`"
            :properties="service"
            :visible-value="true"
          />
        </v-basemap>
      </template>
      <template v-if="viewReady">
        <VWmsLayer
          v-model="visibleLayers"
          :properties="services.DVG"
          :properties-key="SERVICE_KEY"
        />
        <VMapImageLayer
          v-model="visibleLayers"
          :properties="services.VOKAR"
          :properties-key="SERVICE_KEY"
        />
      </template>
    </VMap>

    <template #top-right>
      <VMapToolbar>
        <VMapToolZoomToExtent
          v-model="extent"
          icon="$vuetify.icons.nrw"
          :init-extent="initExtent"
          label="Auf NRW zoomen"
        />
        <VMapTool
          icon="mdi-folder-open"
          label="Auskunft laden"
          @click="dialogLoadAuskunft = !dialogLoadAuskunft"
        />
        <VMapToolCollapseContent
          v-model="sidebar"
          collapse-right
        />
      </VMapToolbar>
    </template>

    <template #bottom-left v-if="auskunft.vokarId">
      <VMapToolbar class="pr-0">
        <v-toolbar-title class="text-body-1 pr-1">{{ auskunft.vokarId }}</v-toolbar-title>
        <VMapToolCollapseContent
          v-model="drawer"
          collapse-bottom
        />
      </VMapToolbar>
    </template>

    <template #footer v-if="!$vuetify.breakpoint.smAndDown">
      <div
        class="d-flex align-center justify-space-between pa-1"
      >
        <VMapCopyright/>
        <VMapCoordinateViewer
          :position="cursorPosition"
        />
        <VMapScaleChanger
          v-model="scale"
          :lods="constraints.lods"
        />
      </div>
    </template>

    <template #drawer>
      <VAuskunftCenter
        v-model="selectedGrundstuecke"
        :auskunft="auskunft"
        :vorschriften="vorschriften"
        :extent="extent"
      />
    </template>

    <template #sidebar>
      <v-subheader>Hintergrundkarten</v-subheader>
      <v-map-basemap-toggler
        v-model="visibleBasemap"
        :items="basemaps"
        item-text="title"
      />
      <v-subheader>
        Karteninhalt
      </v-subheader>
      <v-treeview
        v-model="visibleLayers"
        class="pa-2"
        expand-icon="mdi-chevron-down"
        hoverable
        :items="tocItems"
        :item-key="SERVICE_KEY"
        item-text="title"
        item-children="sublayers"
        open-on-click
        selectable
        selected-color="primary"
      >
        <template v-slot:label="{ item }">
          <div class="d-flex flex-row align-center">
            <v-img
              v-if="item._legend"
              class="mr-2"
              :max-width="20"
              :max-height="20"
              :src="item._legend"
            />
            <span>{{ item.title}}</span>
          </div>
        </template>
      </v-treeview>
    </template>

    <DialogFindAuskunftById
      v-model="dialogLoadAuskunft"
      max-width="600px"
      @item-found="onLoadAuskunft($event.item)"
    />
  </VLayoutMap>
</template>

<script>
import DialogFindAuskunftById from '../../components/DialogFindAuskunftById';
import VAuskunftCenter from './components/VAuskunftCenter';
import VLayoutMap from '../../components/VLayoutMap';
import VMapBasemapToggler from '../../components/VMapBasemapToggler';
import VMapCoordinateViewer from '../../components/VMapCoordinateViewer';
import VMapCopyright from '../../components/VMapCopyright';
import VMapScaleChanger from '../../components/VMapScaleChanger';
import VMapTool from '../../components/VMapTool';
import VMapToolbar from '../../components/VMapToolbar';
import VMapToolCollapseContent from '../../components/VMapToolCollapseContent';
import VMapToolZoomToExtent from '../../components/VMapToolZoomToExtent';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import {VBasemap, VGraphic, VMap, VMapImageLayer, VMapView, VWmsLayer, VWmtsLayer, waitUntil} from 'vuesri';
import {loadModules, setDefaultOptions} from 'esri-loader';

import {toArcGISGraphics} from '../../common/grundstueck';
import {AuskunftRepository} from '../../repositories/auskunft-repository';
import {VersionRepository} from '../../repositories/version-repository';
import {ESRI_API, MAP} from '../../config';
import {DOP20, DOP_OVERLAY, DVG, SERVICE_KEY, VOKAR, WEBATLAS_GRAU} from '../../services';
import {formatDateTime} from "../../common/lang";
import {VorschriftRepository} from "../../repositories/vorschrift-repository";

setDefaultOptions({ ...ESRI_API });

export default {
  provide () {
    return {
      getView: this.getView,
    };
  },
  components: {
    DialogFindAuskunftById,
    VAuskunftCenter,
    VLayoutMap,
    VMapBasemapToggler,
    VMapCoordinateViewer,
    VMapCopyright,
    VMapScaleChanger,
    VMapTool,
    VMapToolbar,
    VMapToolCollapseContent,
    VMapToolZoomToExtent,
    VGraphic,
    VMap,
    VMapImageLayer,
    VMapView,
    VBasemap,
    VWmsLayer,
    VWmtsLayer
  },
  props: {
    user: {},
    auskunftId: {}
  },
  $esriModules: undefined,
  data () {
    return {
      SERVICE_KEY,
      auskunft: {},
      basemaps: [
        {
          id: 'map',
          title: 'Karte',
          services: [
            WEBATLAS_GRAU
          ]
        },
        {
          id: 'dop',
          title: 'Luftbild',
          services: [
            DOP20,
            DOP_OVERLAY
          ]
        }
      ],
      constraints: {
        snapToZoom: true,
        lods: MAP.lods
      },
      cursorPosition: null,
      dialogLoadAuskunft: false,
      drawer: false,
      extent: MAP.initExtent,
      initExtent: MAP.initExtent,
      scale: null,
      selectedGrundstuecke: [],
      services: {
        DVG,
        VOKAR,
      },
      sidebar: !this.$vuetify.breakpoint.smAndDown,
      ui: {
        components: ['zoom']
      },
      version: {},
      vorschriften: [],
      versionList: [],
      viewReady: null,
      visibleBasemap: null,
      visibleGraphics: [],
      visibleLayers: [],
      visibleLayersCopy: []
    };
  },
  computed: {
    versionListFlat () {
      return this.versionList.reduce((accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.schema]: currentValue
      }), {});
    },
    tocItems () {
      return [
        this.services.DVG,
        // Für den VOKAR-Service nur alles unter den ersten Sublayers (vokar_0, vokar_X, ...).
        ...this.services.VOKAR.sublayers
          .filter(sublayer => this.versionListFlat[sublayer.title])
          .map(sublayer => {
            const version = this.versionListFlat[sublayer.title];
            const clone = _cloneDeep(sublayer);
            clone._version = version.id;
            clone.title = version.text;
            return clone;
          }).filter(sublayer => {
            // TOC nach version filtern
            return !this.auskunft.version_id ||
              sublayer._version === this.auskunft.version_id;
          })
      ];
    },
    graphics () {
      return this.auskunft.grundstuecke
        ? toArcGISGraphics(this.auskunft.grundstuecke)
        : [];
    },
    graphicsNormalized () {
      return this.graphics.reduce((accumulator, graphic) => ({
        ...accumulator,
        [graphic.attributes.id]: graphic,
      }), {});
    }
  },
  watch: {
    auskunft (auskunft) {
      this.drawer = !!auskunft;
    },
    async selectedGrundstuecke (values, oldValues) {
      // Visibility der Graphics über die Ausgewählten Grundtücke.
      this.visibleGraphics = values.map(value => value.id);

      // Auf selektierte Grundstücke zoomen
      if (values.length > oldValues.length ||
        (values.length === oldValues.length && !_isEqual(values, oldValues))
      ) {
        await waitUntil(30 * 1000, () => this.viewReady);
        this.extent = await this.getGraphicsExtent(
          // Für die selektierten Grundstücke die entsprechende graphic suchen.
          values.map(grundstueck => {
            return this.graphicsNormalized[grundstueck.id];
          })
        );
      }
    },
    version: {
      async handler (version) {
        if (version && version.id) {
          const result = await this.searchVorschriften(version.id)
          this.vorschriften = result.content
        }
      },
      deep: true
    }
  },
  async created () {
    this.$esriModules = await loadModules([
      'esri/geometry/support/jsonUtils'
    ]);
  },
  async mounted () {
    const result = await this.searchActiveVersions();
    this.versionList = result.content;

    if (this.auskunftId) {
      const auskunft = await this.fetchAuskunftById(this.auskunftId);
      this.setAuskunft(auskunft);
    }
    this.visibleBasemap = this.basemaps.find(Boolean)
  },
  methods: {
    onLoadAuskunft (auskunft) {
      this.setAuskunft(auskunft);
      this.dialogLoadAuskunft = false;
    },

    async setAuskunft (auskunft) {
      const warning = {
        accent: 'darken-2',
        color: 'warning',
        title: 'Warnung',
      };

      if (auskunft.vorkaufsrecht) {
        const version = await this.fetchVersionById(auskunft.version_id);
        if (version.active) {
          this.auskunft = auskunft;
          this.version = version;
        } else {
          const deleted = formatDateTime(version.inactive_since);
          this.$store.commit('SHOW_INFO_DIALOG', {
            ...warning,
            text: `Für die Auskunft liegt kein Datenbestand mehr vor. Version '${version.id}' gelöscht am ${deleted}.`
          });
        }
      } else {
        this.$store.commit('SHOW_INFO_DIALOG', {
          ...warning,
          text: 'Für die Auskunft liegen keine Vorkaufsrechte vor.'
        });
      }
    },

    async fetchAuskunftById (id) {
      try {
        const { data } = await AuskunftRepository.findById(id);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },

    async fetchVersionById (id) {
      try {
        const { data } = await VersionRepository.findById(id);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },

    async searchVorschriften(versionId) {
      try {
        const { data } = await VorschriftRepository.search({
          versionId
        })
        return data
      } catch (error) {
        this.$store.commit('SET_ERROR', error)
        throw error
      }
    },

    async searchActiveVersions () {
      try {
        const { data } = await VersionRepository.search({
          active: true
        });
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },

    async getGraphicsExtent (graphics = []) {
      await waitUntil(30 * 1000, () => this.$esriModules);
      const [jsonUtils] = this.$esriModules;

      let extent = null;

      if (graphics.length > 0) {
        extent = graphics
          .map(graphic => {
            return jsonUtils.fromJSON(graphic.geometry).extent;
          })
          .reduce((accumulator, currentValue) => {
            const clone = accumulator.clone();
            clone.union(currentValue);
            return clone;
          })
          // Extent vergrößern, ansonsten wählt snapToZoom den falschen scale.
          .expand(1.25)
          .toJSON();
      }

      return extent;
    },

    getView () {
      return this.$refs.view.getView();
    }
  }
};
</script>
