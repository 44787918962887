<template>
  <v-list class="pa-2">
    <v-list-item-group
      v-model="local"
      color="primary"
    >
      <v-list-item
        v-for="item in items"
        :key="`basemap-${_get(item, itemKey)}`"
        :value="_get(item, itemKey)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ _get(item, itemText) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import _get from 'lodash/get'

export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'text'
    },
    value: {}
  },
  data () {
    return {
      local: _get(this.value, this.itemKey)
    };
  },
  watch: {
    value (value) {
      const id = _get(value, this.itemKey)
      if (this.local !== id) {
        this.local = id;
      }
    },
    local (local) {
      const id = _get(this.value, this.itemKey)
      if (local !== id) {
        this.$emit('input', this.items.find(item => item.id === local));
      }
    }
  },
  methods: {
    _get
  }
};
</script>

