import { GRAPHICS } from '../config';
import { geojsonToArcGIS } from './geometry';

export function equals (a = {}, b = {}) {
  if (a.id && b.id) {
    return a.id === b.id;
  } else {
    return a.amtsgericht.id === b.amtsgericht.id
      && a.grundbuchbezirk.id === b.grundbuchbezirk.id
      && a.blatt === b.blatt
      && a.lfdnrbestandsvz === b.lfdnrbestandsvz
      && a.buchungsart === b.buchungsart;
  }
}

export function hasVorkaufsrechtForVorschrift (grundstueck = {}, vorschrift) {
  return grundstueck.vorkaufsrecht
      && grundstueck.vorkaufsrechte.some(vorkaufsrecht => vorkaufsrecht.vorschrift.id === vorschrift.id);
}

export function text (grundstueck = {}) {
  if (grundstueck.text) {
    return grundstueck.text
  }
  if (grundstueck.amtsgericht && grundstueck.grundbuchbezirk && grundstueck.blatt && grundstueck.lfdnrbestandsvz) {
    return `${grundstueck.amtsgericht.name} / ${grundstueck.grundbuchbezirk.name} (${grundstueck.grundbuchbezirk.gbbz}) / ${grundstueck.blatt} / ${grundstueck.lfdnrbestandsvz}`
  }
  return null
}

export function flurstueckeText (grundstueck = {}, join = '\n') {
  return grundstueck.flurstuecke
    ? grundstueck.flurstuecke.map(flurstueck => flurstueck.text).join(join)
    : '';
}

export function toArcGISGraphic (grundstueck = {}, symbolTemplate = GRAPHICS) {
  let graphic = null;
  if (grundstueck.geometry) {
    graphic = {
      geometry: geojsonToArcGIS(grundstueck.geometry),
      symbol: toArcGISSymbol(grundstueck, symbolTemplate),
      attributes: {
        id: grundstueck.id
      }
    };
  }
  return graphic;
}

export function toArcGISGraphics (grundstuecke = [], symbolTemplate = GRAPHICS) {
  return grundstuecke
    .map(grundstueck => toArcGISGraphic(grundstueck, symbolTemplate))
    .filter(Boolean); // Entfernt Null Werte.
}

export function toArcGISSymbol (grundstueck = {}, symbolTemplate = GRAPHICS) {
  // Symbol für Graphic abhängig vom Vorkaufsrecht.
  // Kein Vorkaufsrecht -> default
  // Vorkaufsrecht für mehrere Vorschriften -> vokar
  // Ansonsten -> 'VOKAR-Rolle'
  let symbol;
  if (grundstueck.vorkaufsrecht) {
    const roles = grundstueck.vorkaufsrechte.map(vorkaufsrecht => vorkaufsrecht.vorschrift.role);
    symbol = roles.length > 1
        ? symbolTemplate.vokar
        : symbolTemplate[roles[0]];
  } else {
    symbol = symbolTemplate.default;
  }
  return symbol;
}
