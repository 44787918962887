import { DefaultRepository } from './default-repository';

const resource = '/job';
const repository = DefaultRepository.getInstance();

export const JobRepository = {
  findById (id, config = {}) {
    return repository.get(`${resource}/${id}`, config);
  }
};
