<template>
  <v-tooltip
    bottom
    :open-delay="openDelay"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="{...on, ...$listeners}"
        icon
        :aria-label="label"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      required: true,
      default: undefined
    },
    label: {
      type: String,
      required: true,
      default: undefined
    },
    openDelay: {
      type: Number,
      required: false,
      default: 450
    },
  }
};
</script>
