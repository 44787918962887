<template>
  <VMapTool
    :icon="icon"
    :label="text"
    @click="$emit('input', !value)"
  />
</template>

<script>
import VMapTool from '../VMapTool';

export default {
  components: {
    VMapTool
  },
  props: {
    collapseRight: {
      type: Boolean,
      required: false,
      default: false
    },
    collapseBottom: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {}
  },
  computed: {
    icon () {
      if (this.value) {
        if (this.collapseRight) return 'mdi-arrow-collapse-right';
        if (this.collapseBottom) return 'mdi-arrow-collapse-down';
        return 'mdi-arrow-collapse-left';
      } else {
        if (this.collapseRight) return 'mdi-arrow-expand-left';
        if (this.collapseBottom) return 'mdi-arrow-expand-up';
        return 'mdi-arrow-expand-right'
      }
    },
    text () {
      return this.value ? 'Einklappen' : 'Ausklappen';
    }
  }
};
</script>
