import { API } from '../config';

const baseURL = `${API.url}/api/v1/resource`;

export const ResourceRepository = {
  download (id) {
    const link = document.createElement("a");
    link.setAttribute('download', '');
    link.href = `${baseURL}/${id}/download`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};
