import { DefaultRepository } from './default-repository';

const resource = '/auskunft';
const repository = DefaultRepository.getInstance();

export const AuskunftRepository = {
  create (auskunft, config = {}) {
    return repository.post(resource, auskunft, config);
  },
  createAbsage (id, config = {}) {
    return repository.post(`${resource}/${id}/absage`, null, config);
  },
  createZusage (id, config = {}) {
    return repository.post(`${resource}/${id}/zusage`, null, config);
  },
  findById (id, config = {}) {
    return repository.get(`${resource}/${id}`, config);
  },
  export (id, data, config = {}) {
    return repository.post(`${resource}/${id}/export`, data, config);
  }
};
