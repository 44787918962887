<template>
  <VDialog
    v-model="show"
    v-bind="$attrs"
    max-width="450px"
  >
    <VDialogContent
      title="Auskunft laden"
    >
      <v-form
        ref="form"
        @submit.prevent
      >
        <v-text-field
          ref="input"
          v-model="id"
          autocomplete="off"
          autofocus
          :error-messages="errorMessages"
          hint="VOKAR-ID oder ID"
          label="Auskunft ID"
          maxlength="18"
          persistent-hint
          :rules="rules"
          @change="errorMessages = ''"
          @keyup.enter.native="onSubmit"
        ></v-text-field>
      </v-form>
      <template #actions>
        <v-spacer></v-spacer>
        <VBtnResponsive
          color="grey lighten-3"
          depressed
          tile
          @click="show = false"
        >
          Schlie&szlig;en
        </VBtnResponsive>
        <VBtnResponsive
          color="primary"
          depressed
          :disabled="loading"
          :loading="loading"
          tile
          @click="onSubmit"
        >
          Auskunft laden
        </VBtnResponsive>
      </template>
    </VDialogContent>
  </VDialog>
</template>

<script>
import VBtnResponsive from '../VBtnResponsive';
import VDialog from '../VDialog';
import VDialogContent from '../VDialogContent';

import _has from 'lodash/has';
import _throttle from 'lodash/throttle';

import {AuskunftRepository} from '../../repositories/auskunft-repository';
import {requiredRule} from "../../common/lang";

const VOKAR_PREFIX = 'VOKAR-';
const EVENTS = {
  itemFound: 'item-found'
};

export default {
  components: {
    VBtnResponsive,
    VDialog,
    VDialogContent
  },
  props: {
    value: {}
  },
  data () {
    return {
      id: '',
      loading: false,
      show: this.value,
      errorMessages: '',
      rules: [
        requiredRule('Geben Sie die ID der Auskunft ein.'),
        value => {
          const pattern = /^[0-9]+$|^VOKAR-[0-9]+$/;
          return pattern.test(value) || 'Die eingegebene ID ist ungültig.';
        }
      ]
    };
  },
  watch: {
    show (show) {
      if (show !== this.value) {
        this.$emit('input', show);
      }
      if (show && this.$refs.form) {
        this.id = '';
        this.errorMessages = [];
        this.$refs.form.resetValidation();
      }
    },
    value (value) {
      if (value !== this.show) {
        this.show = value;
      }
    },
  },
  methods: {
    onSubmit: _throttle(async function () {
      if (this.$refs.form.validate()) {
        const id = this.id.replace(VOKAR_PREFIX, '');
        const found = await this.fetchAuskunftById(id);
        if (found) {
          this.show = false;
          this.$emit(EVENTS.itemFound, {
            item: found
          });
        }
      }
    }, 450, {trailing: false}),
    async fetchAuskunftById (id) {
      try {
        this.loading = true;
        const {data} = await AuskunftRepository.findById(id);
        return data;
      } catch (error) {
        if (_has(error, 'response.status') &&
          (error.response.status === 404 || error.response.status === 403)
        ) {
          this.errorMessages = [error.response.data.message];
        } else {
          this.$store.commit('SET_ERROR', error);
        }
        throw error;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
