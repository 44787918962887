<template>
  <div class="d-flex align-center" :style="style">
    <span class="pr-1">Maßstab:</span>
    <v-select
      class="d-inline-block pa-0 ma-0"
      :items="items"
      hide-details
      :value="value"
      @input="$emit('input', $event)"
    ></v-select>
  </div>
</template>

<script>
import _floor from 'lodash/floor';

export default {
  props: {
    lods: {},
    value: {},
    maxWidth: {
      type: String,
      required: false,
      default: '175px'
    }
  },
  computed: {
    items () {
      return this.lods.map(lod => {
        return {
          text: '1:' + _floor(lod.scale).toLocaleString(),
          value: lod.scale
        };
      });
    },
    style () {
      return {
        'max-width': this.maxWidth
      }
    }
  }
};
</script>

